import React from 'react';
import { FooterBoxContent } from '../components/layout/Footer';
import Layout from '../components/layout/Layout';
import ContentSection from '../pages-components/use-cases-and-news/ContentSection';

const NewsroomPage = (): JSX.Element => {
  return (
    <Layout
      backgroundColor="secondary"
      description="See our products and services in action. Get the latest news from the industry and our company."
      footerBoxContent={FooterBoxContent.ContactInvitation}
      title="Use Cases &amp; News | Antibody Engineering Insights"
    >
      <ContentSection />
    </Layout>
  );
};

export default NewsroomPage;
