import { graphql, useStaticQuery } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import styled, { css } from 'styled-components';
import {
  Upheader,
  TextLarge,
} from '../../components/atoms/Typography/Typography';
import { MainContentColumnWrapper } from '../../components/layout/Layout';
import {
  forScreenAtMost1000pxWide,
  forScreenAtMost1024pxWide,
  forScreenAtMost680pxWide,
} from '../../styles/mediaQueries';
import { TabContentTitle } from '../resources/Tabs';
import PublicationTile from './PublicationTile';

const query = graphql`
  query UseCaseListQuery {
    allSanityUsecases(sort: { fields: [publicationDate], order: DESC }) {
      edges {
        node {
          _id
          title
          abstract
          tag
          slug {
            current
          }
          image {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;

export const ContentSection = (): JSX.Element => {
  const { allSanityUsecases } = useStaticQuery(query);

  return (
    <Wrapper>
      <MainContentColumnWrapper>
        <InnerWrapper>
          <UseCasesInfo>
            <TabContentTitle>Use Cases &amp;&nbsp;News</TabContentTitle>
            <TextLarge $color="tertiary" style={{ maxWidth: 350 }}>
              See our&nbsp;products and&nbsp;services in&nbsp;action. Get
              the&nbsp;latest&nbsp;news from the&nbsp;industry and&nbsp;our
              company.
            </TextLarge>
          </UseCasesInfo>
          <UseCasesContent>
            <UseCasesUpheader $color="delicateAccented">
              Latest publications
            </UseCasesUpheader>
            <PublicationsList>
              {allSanityUsecases.edges.map(
                ({
                  node,
                }: {
                  node: {
                    abstract: string;
                    _id: string;
                    tag: string;
                    title: string;
                    image?: {
                      asset: { gatsbyImageData: IGatsbyImageData };
                    };
                    slug: {
                      current: string;
                    };
                  };
                }) => (
                  <PublicationTile
                    abstract={node.abstract}
                    image={node.image?.asset.gatsbyImageData}
                    key={node._id}
                    slug={node.slug.current}
                    tag={node.tag}
                    title={node.title}
                  />
                ),
              )}
            </PublicationsList>
          </UseCasesContent>
        </InnerWrapper>
      </MainContentColumnWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 200px 0 241px;

  ${forScreenAtMost1000pxWide(
    css`
      padding-bottom: 350px;
    `,
  )}

  ${forScreenAtMost680pxWide(
    css`
      padding-bottom: 300px;
    `,
  )}
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;

  ${forScreenAtMost1024pxWide(css`
    flex-direction: column;
    gap: 0px;
  `)}
`;

const UseCasesInfo = styled.div`
  width: 328px;

  ${forScreenAtMost1024pxWide(css`
    width: 100%;
  `)}
`;

const UseCasesContent = styled.div`
  width: 746px;
  padding-top: 57px;

  ${forScreenAtMost1024pxWide(css`
    width: 100%;

    ${Upheader} {
      text-align: center;
    }
  `)}
`;

const PublicationsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;
`;

const UseCasesUpheader = styled(Upheader)`
  margin-bottom: 30px;
`;

export default ContentSection;
