import React from 'react';
import styled, { css } from 'styled-components';
import { AccentedLinkBig } from '../../components/atoms/AccentedLink';
import { RegularLink } from '../../components/atoms/Link';
import {
  Header,
  TextMedium,
} from '../../components/atoms/Typography/Typography';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import colors from '../../styles/colors';
import { forScreenAtMost680pxWide } from '../../styles/mediaQueries';

const PublicationTileText = styled.div`
  padding: 30px 30px 0 30px;
`;

const PublicationTileHeader = styled(Header)`
  margin-bottom: 30px;
`;

const PublicationTileAbstract = styled(TextMedium)`
  margin-bottom: 30px;
`;

const PublicationTileReadMore = styled(AccentedLinkBig).attrs((props) => ({
  ...props,
  $accentColor: 'secondary',
  $color: 'tertiary',
  tabIndex: 0,
}))`
  margin-bottom: 30px;
`;

const PublicationTileTags = styled.div`
  margin-top: 10px;
  margin-bottom: 30px;
  color: ${colors.seaBlue};
  background-color: ${colors.lightenSeaBlue};
  padding: 5px 15px;
  border-radius: 50px;
  width: fit-content;
`;

const PublicationTile = ({
  className,
  title,
  abstract,
  image,
  tag,
  slug,
}: {
  className?: string;
  title: string;
  abstract: string;
  image?: IGatsbyImageData;
  tag: string;
  slug: string;
}): JSX.Element => {
  const useCaseURL = `/use-case/${slug.toLocaleLowerCase()}/`;

  return (
    <section className={className}>
      {image && <GatsbyImage alt={title} image={image} />}
      <PublicationTileText>
        <RegularLink to={useCaseURL}>
          <PublicationTileHeader $color="tertiary">
            {title}
          </PublicationTileHeader>
        </RegularLink>
        <PublicationTileAbstract $color="delicateStronger">
          {abstract}
        </PublicationTileAbstract>
        <PublicationTileReadMore to={useCaseURL}>
          Read more
        </PublicationTileReadMore>
        <PublicationTileTags>{tag}</PublicationTileTags>
      </PublicationTileText>
    </section>
  );
};

export default styled(PublicationTile)`
  width: 358px;
  background-color: ${colors.almostWhite};

  ${forScreenAtMost680pxWide(css`
    margin: 0 -30px 40px -30px;
    width: 375px;
  `)}
`;
